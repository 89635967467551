
.report-page-container {

  .form-header {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: flex;
    justify-content: center;

    .field-select {
      width: 10em;
    }
  }

  .categoryChart {
   display: flex;
    justify-content: center;
    margin-top: 2em;

    .parent-apex-chart {
      width: 30%;
    }
  }

  .stats-section {
    display: flex;
    justify-content: center;
  }

  .apexcharts-menu-icon {
    display: none;
  }

  .total-amount-spent {
    display: flex;
    justify-content: center;
    padding-top: 1em;

    div {
      display: flex;
      flex-direction: column;
      background: #c5e0fa;
      padding: 1em 8.5em 1em 2em;
      border-radius: 0.8em;
    }

    span:nth-child(1) {
      font-size: 1.5em;
      font-weight: 300;
    }
    span:nth-child(2) {
      font-size: 2em;
      font-weight: 500;
    }
  }

  .top-expenses {
    a:link { text-decoration: none; }
    a:visited { text-decoration: none; }
    a:hover { text-decoration: none; }
    a:active { text-decoration: none; }
    a, a:visited, a:hover, a:active {
      color: inherit;
    }
    display: flex;
    justify-content: center;
  }

  .sub-catgory-expenses {
    margin-left: 2em;
  }

  .all-transactions-section {
    .btn-transaction {
      display: flex;
      justify-content: center;
      margin-top: 2em;
    }
  }

  @media only screen and (max-width: 960px) {

    .categoryChart {
      .parent-apex-chart {
        width: 100%;
      }
    }

    .stats-section {
      flex-direction: column;
    }

    .sub-catgory-expenses {
      margin-top: 1em;
      margin-left: 0em;
    }


  }


}