.circular-loading-section {

  position: absolute;
  top: 0%;
  left: 0%;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255,255,255, 60%);

  .loading-content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
}