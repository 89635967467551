
.notification-status-dialog {
  width: 20em;

  .title {
    padding-bottom: .5em;
    span:nth-child(1) {
      font-size: 1.2em;
      font-weight: 200;
    }
  }

  .message {
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      font-size: 1em;
      font-weight: 400;
    }

    span:nth-child(2) {
      font-size: 1em;
      font-weight: 200;
    }
  }
}

.notification-page-section {

  display: flex;
  flex-direction: column;

  .heading {
    font-size: 1.2em;
    padding-top: .5em;
  }

  .main-notification-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em;

    .description {
      border-top: 1px solid #dedede;
      margin-top: 1em;
      padding-top: 1em;
    }

    .notification-all-items {
      background: white;
      margin-top: 2em;
    }
  }

  @media only screen and (max-width: 680px) {

    .main-notification-section .notification-all-items {
      padding: 0em;
    }

  }

}