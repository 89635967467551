.transaction-page-container {

  .monthly-stats {
    margin-top: 1em;
    padding: 0em 0.8em;

    .monthly-view-container {
      margin: 0em;
    }
  }

  .no-recent-transaction {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }

}