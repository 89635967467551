
.divider-item {
  border-bottom: 1px solid #d2d2d2;
}

.notificationItem-inactive {
  background-color: #838383;
  color: #dbdbdb;
  opacity: .4;
}

.notificationItem-section {

  .notification-item-header {
    display: flex;
    padding: .5em .8em;

    .category-icon {
      img {
        width: 3.5em;
      }

      .notify-icon {
        position: relative;
        top: -.6em;
        right: .6em;
      }

      .nofity-active {
        color: green;
      }

      .nofity-inactive {
        color: black;
      }
    }

    .notification-main-div {
      display: flex;

      .notification-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 12em;
        font-weight: 500;

        .category-title {
          font-size: 0.9em;
          font-weight: 200;
        }
      }

      .notification-other-details {
        padding-left: 1.5em;
        font-size: 0.9em;

        .notification-date {

        }
      }
    }
  }

  @media only screen and (max-width: 680px) {

    .notification-item-header .notification-main-div .notification-title {
      width: 13em;
    }
  }


  @media only screen and (max-width: 429px) {

    .notification-item-header .notification-main-div .notification-title {
      width: 11em;
    }
  }

}