
.modal-container {
  display: flex;
  justify-content: center;
  padding-top: 3em;

  .modal {
    width: 40%;
  }

  h4 {
    padding-bottom: .25em;
    border-bottom: 1px solid #cecccc;
  }

  .circularProgress {
    margin-left: 0px;
    margin-right: 1em;
  }
}

.modal-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(58, 57, 57, 0.6);
  z-index: 2;
}

.modal-show {
  z-index: 1003;
  display: block;
  opacity: 1;
  transform: scaleX(1);
  top: 10%;
}

.modal-open {
  overflow: hidden;
}