
.add-reminder-screen-section {

  .select-category-icon, .wallet-icon {
    width: 2em;
    margin-right: 0.5em;
  }

  .reminder-form-header {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: grid;
    // desktop
    grid-template-columns: 1fr 1fr;

    grid-gap: 1em;

    .field-title {
      grid-column: span 2;
    }

    .field-message {
      grid-column: span 2;
    }

    .footer-info {
      grid-column: span 2;
      color: red;
    }
  }

  // mobile css
  @media only screen and (max-width: 680px) {

    .reminder-form-header {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      display: grid;

      //mobile
      grid-template-columns: 1fr;
      grid-gap: 1em;

      .field-message, .field-category, .field-reminderType, .field-date {
        grid-column: span 2;
      }


    }

  }
}