.select-category-icon, .wallet-icon {
  width: 2em;
  margin-right: 0.5em;
}

.add-update-transaction-dialog {

  .add-more-details {
    padding: 1em;
    text-decoration: none;
  }

  .checkbox-sub-text {
    margin-top: -1em;
    margin-left: 2.5em;
  }


  .transaction-form-header {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: grid;
    // desktop
    grid-template-columns: 1fr 1fr;

    grid-gap: 1em;

/*    .wallet-icon {
      width: 1em;
    }*/

    .field-wallet {
      order: 1;
    }
    .field-category {
      order: 2;
    }
    .field-amount {
      order: 3;
    }
    .field-sub-category {
      order: 4;
    }
    .field-date {
      order: 5;
    }
    .field-spend {
      order: 6;
    }
  }

  .transaction-form-extra {
    display: grid;
    // desktop
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;

    // part 2 grid
    .field-paidFrom {

    }
    .field-location {

    }
    .field-note {
      grid-column: span 2;
    }
    .field-important {
      grid-column: span 2;
    }
    .field-exclude {
      grid-column: span 2;
    }
  }

  // mobile css
  @media only screen and (max-width: 680px) {

    .transaction-form-header {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      display: grid;

      //mobile
      grid-template-columns: 1fr;
      grid-gap: 1em;

      /*.wallet-icon {
        width: 1.5em;
      }*/

      .field-wallet {
        order: 1;
      }
      .field-category {
        order: 2;
      }
      .field-amount {
        order: 5;
      }
      .field-sub-category {
        order: 3;
      }
      .field-date {
        order: 6;
      }
      .field-spend {
        order: 4;
      }
    }

    .transaction-form-extra {

      display: grid;
      // desktop
      //grid-template-columns: 1fr 1fr;
      grid-gap: 1em;

      //mobile
      grid-template-columns: 1fr;

      // part 2 grid
      .field-paidFrom {
        grid-column: span 2;
      }
      .field-location {
        grid-column: span 2;
      }
      .field-note {
        grid-column: span 2;
      }
      .field-important {
        grid-column: span 2;
      }
      .field-exclude {
        grid-column: span 2;
      }
    }

  }

}