
.loading-screen-section {

  align-items: center;
  //background: rgba(255,255,255,0);
  background: #eef2f6;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 9999;

  .loading-content {
    width: 40%;
  }
}

