
.create-category-section {


  .create-category-form {
    padding-top: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  @media only screen and (max-width: 680px) {

    .create-category-form {
      grid-template-columns: 1fr;
    }

  }
}