
.subcategory-stats-section {

  display: flex;
  justify-content: center;

  .card-content-main {

    .title {
      display: flex;
      flex-direction: column;
      padding-left: 0.3em;
      line-height: 1.4;
      justify-content: center;
      border-bottom: 1px solid #f3efef;
      padding-bottom: .6em;
      margin-bottom: 1em;
    }

    .field-category {
      width: 100%;

      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        height: 1.2em;
      }
    }

    .totalSpent {
      padding: .5em;
      margin-top: 1em;
      background: #e5e5e5;
      border-radius: 1em;
      text-align: center;
      color: #3f51b5;
      font-weight: 600;
    }

    .not-found-section {
      height: 6em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #888888;
      width: 20em;
    }
  }

  @media only screen and (max-width: 960px) {

    .not-found-section {
      width: inherit;
    }
  }

}