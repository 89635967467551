.subcategory-stats-item-section {

  padding: .8em;

  .sub-category-header {
    display: flex;
  }

  .category-icon {
    padding: 0.5em;

    span {
      font-size: 2em;
    }

    img {
      width: 2.8em;
    }
  }

  .sub-category-item {
    width: 14em;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 0.3em;
    line-height: 1.4;
    justify-content: center;

    span:nth-child(1) {
      font-weight: 500;
    }
    span:nth-child(2) {
      color: #78797a;
      font-size: 13px;
    }

  }

  .amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.5em;
    color: red;
    font-size: 1.1em;
    font-weight: 300;
  }

  @media only screen and (max-width: 960px) {

    .sub-category-item {
      width: inherit;
     }
  }

}