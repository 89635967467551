
.recent-transaction-section {
    display: flex;
    justify-content: center;
    //background-color: #e2e2e2;
    padding-top: 1em;

    a:link { text-decoration: none; }
    a:visited { text-decoration: none; }
    a:hover { text-decoration: none; }
    a:active { text-decoration: none; }
    a, a:visited, a:hover, a:active {
        color: inherit;
    }

    hr {
        background-color: #787878;
    }
}

.editTransaction {

    margin-left: 1em;
    height: max-content;
    width: 50%;


    .header {
        display: flex;
        justify-content: space-between;

        span {
            font-size: large;
            padding: 0.8em;
            padding-left: 1.6em;
        }

        .button-close {
            font-size: x-large;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
    }
    .content-details-left {
        display: flex;
        flex-direction: row;
        padding-left: 0.5em;

        .transaction-details {
            display: flex;
            flex-direction: column;
        }

        .category {
            font-size: xx-large;
        }

        .amount {
            font-size: x-large;
            color: red;
            margin-top: 0.3em;
            margin-bottom: 0.4em;
        }

        .date {
            font-size: x-small;
            padding-bottom: 0.5em;
        }
    }

    .content-details-right {

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .wallet {
            text-align: right;
            padding: 0.2em 0.5em;
        }

        .important {
            color: #ffba0f;
        }

        .delete-transaction, .edit-transaction {
            font-size: 0.7em;
        }

        .action-buttons {
            padding-right: 0.5em;
            padding-bottom: 0.5em;
        }
    }

    .logo {
        padding: 1em;
    }


}