.transaction-details-section {

  margin-left: 1em;
  height: max-content;
  margin-right: 1em;
  margin-top: 1em;

  .header {
    display: flex;
    justify-content: space-between;
    background: aliceblue;

    span {
      font-size: large;
      padding: 0.8em;
    }

  }

  .important-star {
    color: #ffc200;
    padding-right: 0.5em;
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1em;
  }

  .content-details-left {
    display: flex;
    flex-direction: row;
    padding-left: 0.5em;

    .transaction-details {
      display: flex;
      flex-direction: column;
    }

    .category {
      font-size: xx-large;
    }

    .amount {
      font-size: x-large;
      color: red;
      margin-top: 0.3em;
      margin-bottom: 0.4em;
    }

    .amount-exclude {
      color: #595959;
    }

    .amount-credited {
      color: #22a022;
    }

    .date {
      font-size: small;
      padding-bottom: 0.5em;
    }

    .note {
      font-size: 0.8em;
    }
  }

  .bottom-divider {
    margin-top: 2em;
  }
  .content-details-right {

    padding-top: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .important {
      color: #ffba0f;
    }

    .action-buttons {
      padding-right: 0.5em;
      padding-bottom: 0.5em;
      display: flex;
      justify-content: space-around;

      > button {
        width: 100%;
      }
    }
  }

  .wallet-div {

    display: flex;
    justify-content: space-between;

    .wallet {
      text-align: right;
      padding: 0.2em 0.5em;
      text-align: right;
      padding: 0.2em 0.5em;
      background: aliceblue;
    }

    .date {
      padding-left: 1em;
    }
  }

  .logo {
    padding: 1em;

    img {
      width: 3.5em;
    }
  }
}