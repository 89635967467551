.other-category-simple-item-section {

  display: flex;
  border-bottom: 1px solid #f3efef;
  padding: .5em;

  .category-item {
    width: 12em;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 0.3em;
    line-height: 1.4;
    justify-content: center;

    span:nth-child(1) {
      font-weight: 500;
    }
    span:nth-child(2) {
      color: #78797a;
      font-size: 13px;
    }

  }

  .amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.5em;
    color: red;
    font-size: 1.1em;
    font-weight: 300;
  }
  .amount-exclude {
    color: #787878;
  }
}