
.reminder-page-section {
  display: flex;
  flex-direction: column;

  .heading {
    font-size: 1.2em;
    padding-top: .5em;
  }

  .main-reminder-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .add-reminder-btn {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .description {
      border-top: 1px solid #dedede;
      margin-top: 1em;
      padding-top: 1em;
    }

    .reminder-all-items {
      background: white;

    }
  }

  a:link { text-decoration: none; }
  a:visited { text-decoration: none; }
  a:hover { text-decoration: none; }
  a:active { text-decoration: none; }
  a, a:visited, a:hover, a:active {
    color: inherit;
  }

  hr {
    background-color: #787878;
  }

}

@media only screen and (max-width: 680px) {

  .main-reminder-section {
    .description {
      padding: 1em;
    }
  }

}