
.home-container {

  .monthly-stats {
    display: flex;
    margin-top: 1em;
    justify-content: center;

    .div-separator {
      width: 1em;
    }
  }

  .no-recent-transaction {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }
}