body {
  background-color: #eef2f6;
}

.App {
  background-color: rgb(238, 242, 246);
}

.header-top-padding {
  padding-top: 4em;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
  padding-bottom: 3em;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.link-primary {
  cursor: pointer;
}

.form-error-message {
  color: red;
  font-size: 14px;
}

.link-primary {
cursor: pointer;
}

.forgot-remember {
  display: flex;
  justify-content: space-between;
}

.btn-google-sign-in {
  display: flex !important;
  justify-content: space-between !important;
}

.btn-google-sign-in-icon {
  width: 1.5em;
}