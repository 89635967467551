
.wallet-page-section {
  display: flex;
  justify-content: center;

  .add-new-wallet {
    margin-top: 1em;
  }

  .wallet-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .wallet-list {

    margin-top: 3em;

    .heading {
      font-size: 1.3em;
      display: inherit;
      border-bottom: 1px solid grey;
    }

    .wallet-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0.6em 0em;

      img {
        width: 3.8em;
        padding-left: 0.8em;
        padding-right: 0.8em;
      }
    }

    .wallet-title {
      flex: 1;
      display: flex;
      flex-direction: column;
      line-height: 1.2;

        span:nth-child(1) {
          font-weight: 500;
        }
        span:nth-child(2) {
          color: #78797a;
          font-size: 13px;
        }

        .default-wallet {
          font-size: 12px;
          border-radius: 3px;
          padding: 3px;
          background: green;
          color: white;
        }
    }

  }
}