
.divider-item {
  border-bottom: 1px solid #d2d2d2;
}

.reminderItem-section {
  padding: 0.5em 1em;



  .reminder-item-header {
    display: flex;
    align-items: center;

    .category-icon img {
      width: 3.5em;
    }

    .reminder-main-div {
      padding-left: 1em;

      .reminder-title {
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 15em;
        padding-bottom: .4em;
        display: flex;
        justify-content: space-between;
      }
    }

    .reminder-other-details {
      display: flex;
      justify-content: space-between;
    }

    .category-section {
      display: flex;
      flex-direction: column;

      .category-title {
        font-size: 0.8em;
        margin-top: -.4em;
      }


      .repeat {
        font-size: 0.8em;

        .icon-repeat {
          font-size: medium;
        }
      }
    }

    .date-section {
      display: flex;
      flex-direction: column;
      padding-left: 3em;
      font-size: 0.8em;
      align-self: flex-end;
    }
  }
}