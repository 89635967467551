
.category-page-section {

  display: flex;
  flex-direction: column;

  .heading {
    font-size: large;
  }

  .css-u7qq7e {
    padding: 0px; // prod issue fixed
  }
  .css-17o5nyn.Mui-expanded {
    margin: 0px; // prod issue fixed
  }

  .main-category-section , .sub-category-section  {
      padding-top: 1em;
      padding-left: 1em;

    Button {
      height: fit-content;
    }

    #panel1bh-header {
      background-color: #e4e5ea;
    }
  }

  .main-category-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .add-category-btn {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
  .main-category-section > div, .sub-category-section > div {
    margin-left: 1em;
  }

  .sub-category-section {
    display: flex;
  }

  .main-category-action-icons {
    display: flex;
    justify-content: right;
    padding-right: 0.3em;
    background-color: #e4e5ea;
  }

  .accordion-main {
    width: 30%;
  }

  .category-icon {
    width: 3em;
  }

  .MuiAccordionSummary-content {
    margin: 8px 0;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding:0px;
  }

  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0;
  }

  .category-title {
    display: flex;
    align-items: center;
  }

  ul {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  li {
    border-bottom: 1px solid #f3f3f3;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  @media only screen and (max-width: 920px) {
    .accordion-main {
      width: 60%;
    }
  }

  // mobile css
  @media only screen and (max-width: 680px) {

    .main-category-section , .sub-category-section  {
      padding-right: 1em;
    }

    .main-category-section > div, .sub-category-section > div {
      margin-left: 0em;
    }

    .accordion-main {
      width: 100%;
    }
  }
}