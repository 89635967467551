
.important-flag {
  width: 5px;
  position: absolute;
  height: 3.5em;
  background: #ffc200;
}

.transaction-item-section {

  display: flex;
  border-bottom: 1px solid #f3efef;
  padding-left: 1em;

  .category-icon {
    padding: 0.5em;

    span {
      font-size: 2em;
    }

    img {
      width: 3.3em;
    }
  }

  .category-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 0.3em;
    line-height: 1.4;
    justify-content: center;

    span:nth-child(1) {
      font-weight: 500;
    }
    span:nth-child(2) {
      color: #78797a;
      font-size: 13px;
    }

  }

  .amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.5em;
    color: red;
    font-size: 0.9em;
  }
  .amount-exclude {
    color: #787878;
  }
  .amount-credit {
    color: green;
  }
}

.transaction-item-section:hover {
  background-color: #f1f1f4;
}

.transaction-item-date-header {

  display: flex;
  border-top: 1.5em solid #e2e2e2;
  border-bottom: 1px solid #f3efef;
  background: ghostwhite;

  .date_day {
    padding: 0.5em;

    span {
      font-size: 2em;
    }
  }

  .date_description {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 0.3em;
    line-height: 1.2;
    justify-content: center;

    span:nth-child(1) {
      font-weight: 400;
      color: #595959;
    }
    span:nth-child(2) {
      color: #78797a;
      font-size: 13px;
    }

  }

  .total_amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.5em;
    font-size: 0.9em;
    font-weight: 500;
  }

}