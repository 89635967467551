
.reminder-details-section {

  margin-left: 1em;
  height: max-content;
  margin-right: 1em;
  margin-top: 1em;
  display: flex;
  justify-content: center;

  .card-details {
    width: fit-content;
    padding: 0em;
  }

  .reminder-details-header {

    .category-header {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      .category-icons {
        width: 4em;
        margin: .5em 0 .5em .5em;
      }

      .reminder-category-title {
        display: flex;
        flex-direction: column;
        padding-left: 1em;

        span:nth-child(1) {
          font-size: 1.2em;
          font-weight: 500;
        }

        span:nth-child(2) {
          font-size: 1em;
        }
      }
    }

    .reminder-details {
      padding-top: 1em;
      margin-left: 5em;

      > div {
        padding: .5em 0;
      }

      div:nth-child(4) {
        width: 20em;
      }
    }

    .reminder-upcoming {
      margin-top: 2em;
      font-style: italic;
      padding-bottom: 1em;

      div {
        display: flex;
        flex-direction: column;
      }

      span:nth-child(1) {
        font-size: .9em;
        font-weight: 600;
        margin-left: 5em;
        color: green;
        font-style: normal;
      }

      span:nth-child(2) {
        font-size: .9em;
        margin-left: 5em;
      }

      .error-message {
        color: red !important;
        font-weight: 400 !important;
        font-style: italic !important;
        margin-right: 1em;
      }
    }

    .content-details {
      padding-top: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .action-buttons {
        padding-top: 0.5em;
        display: flex;
        justify-content: space-between;

        > button {
          width: 100%;
        }
      }
    }

  }



  @media only screen and (max-width: 680px) {

    .reminder-details-header .category-header {
      margin-right: 0em;
    }

    .reminder-details-header .reminder-details div:nth-child(4) {
      width: 18em;
    }

  }

}